import { template as template_3691cdad24494854a6c5fb6cc2cf2150 } from "@ember/template-compiler";
const WelcomeHeader = template_3691cdad24494854a6c5fb6cc2cf2150(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
