import { template as template_fa45a4e4fb7e46498e0a1c7c14b2bc67 } from "@ember/template-compiler";
const SidebarSectionMessage = template_fa45a4e4fb7e46498e0a1c7c14b2bc67(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
